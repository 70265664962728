import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { rootUrl, userKey } from "../../../App";
import { useCurrency } from "../../../hooks/useCurrency";
import { useDispatch, useSelector } from "react-redux";
import { currentUserWallet, fetchLoginUserWallet, userDetail } from "../../../Redux/userSlice";

// import { motion } from "framer-motion";
// import useGameErrors from "../../../hooks/useGameErrors";

// import Swal from "sweetalert2";
// import { createPortal } from "react-dom";
// import LauncherErrorAlert from "../../../Components/Shared/CustomSweetAlert/LauncherErrorAlert";
import { TbRefresh } from "react-icons/tb";
import { FaPlus } from "react-icons/fa6";

const fullScreen = process.env.PUBLIC_URL + "/Assets/Images/Icons/GameLaunchIcons/full-screen.svg";
const cross = process.env.PUBLIC_URL + "/Assets/Images/Icons/GameLaunchIcons/cross.svg";

const sandTimer = process.env.PUBLIC_URL + "/Assets/Images/statics/processing.gif";


const BetByLauncher = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const betbyRef = useRef(null);

    const user = useSelector(userDetail);
    const userWallet = useSelector(currentUserWallet);
    const { currency } = useCurrency();

    const requiredCurrencyIcon = currency?.filter((filteredItem) => (filteredItem?.currency === user?.user?.currency));

    const [activeRefresh, setActiveRefresh] = useState(true);

    const refreshBtn = () => {
        setActiveRefresh(true);
    };

    useEffect(() => {
        let refreshTimer;
        if (activeRefresh) {
            // Start refreshing
            dispatch(fetchLoginUserWallet());
            refreshTimer = setTimeout(() => {
                // Stop refreshing after 1 second
                setActiveRefresh(false);
            }, 1000);
        }
        // else {
        //     // Start a timer to refresh after 30 seconds if not already refreshing
        //     refreshTimer = setTimeout(() => {
        //         setActiveRefresh(true);
        //     }, 30000);
        // }
        return () => clearInterval(refreshTimer);
    }, [activeRefresh, dispatch]);

    const [jwtToken, setJwtToken] = useState(null);

    const {
        brandId, fromRoute,
    } = useParams();

    // const betByToken = localStorage.getItem("betbyToken");
    const betbyLaunchScreen = localStorage.getItem("betbyLaunchScreen");

    // const { findErrorByCode, closeErrorAlert, showGameErrorsSweetAlert2, errorAlert, } = useGameErrors();

    // const [isOpen, setIsOpen] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    // const [customLoader, setCustomLoader] = useState(true);

    const handleFullScreen = () => {
        setShowHeader(false);
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    };


    const btInstanceRef = useRef(null); // Store the instance to kill it later

    useEffect(() => {
        const scriptId = "bt-renderer-script";

        let stringValue = localStorage.getItem(userKey);
        if (stringValue === null) return;

        const fetchJwtToken = async () => {
            // const betByToken = localStorage.getItem("betbyToken");
            // console.log(betByToken);

            if (!brandId || !fromRoute || !rootUrl || !userKey) return;

            let stringValue = localStorage.getItem(userKey);
            if (stringValue === null) return;
            let value = JSON.parse(stringValue);

            let url = `${rootUrl}/auth/user/jwt-token/`;

            try {
                let response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${value?.value}`,
                    }
                });

                if (!response.ok) throw response;
                else {
                    let data = await response.json();
                    if (data?.status === "ok") {
                        // console.log((jwtToken === data?.jwt_token), jwtToken, data?.jwt_token);
                        if (!jwtToken) {
                            setJwtToken(data?.jwt_token);
                            return;
                        }
                        // localStorage.setItem("betbyToken", data?.jwt_token);
                        return data?.jwt_token;
                    }
                }
            } catch (error) {
                console.log(error);
            }
            return null;
        };

        // Function to initialize sportsbook
        const initializeSportsbook = () => {
            if (!window.BTRenderer) {
                // console.error("BTRenderer is not available after script load");
                return;
            }

            if (!jwtToken || !brandId || !fromRoute) {
                // console.warn("Missing required parameters for BTRenderer initialization");

                fetchJwtToken();
                return;
            }

            // Initialize and store the instance
            btInstanceRef.current = new window.BTRenderer().initialize({
                brand_id: brandId || `2496668082693611527`,
                token: jwtToken || null,
                themeName: "default",
                lang: "en",
                target: betbyRef.current,
                stickyTop: 0,
                betslipZIndex: 999,
                onSessionRefresh: async () => {
                    await fetchJwtToken();
                },
                onTokenExpired: async () => {
                    const token = await fetchJwtToken();
                    // console.log("ex-token", token);
                    return token;
                },
                onRecharge: () => {
                    if (betbyLaunchScreen === "mobile") {
                        navigate("/deposit");
                        return;
                    }
                    window.close();
                    if (window.opener) {
                        window.opener.location.href = "/deposit";
                    }
                }
            });
        };

        const loadScriptAndInitialize = () => {
            const existingScript = document.getElementById(scriptId);

            if (!existingScript) {
                // Add the script if not already present
                const script = document.createElement("script");
                script.id = scriptId;
                // script.src = "https://ui.invisiblesport.com/bt-renderer.min.js";
                script.src = "https://xbitpro.sptpub.com/bt-renderer.min.js";
                script.async = true;

                script.onload = () => {
                    // console.log("bt-renderer script loaded successfully");
                    initializeSportsbook(); // Initialize once the script is loaded
                };

                script.onerror = () => {
                    // console.error("Failed to load bt-renderer script");
                };

                document.body.appendChild(script);
            } else if (window.BTRenderer) {
                // If script is already loaded and BTRenderer is available
                initializeSportsbook();
            } else {
                // Wait for BTRenderer to become available
                existingScript.addEventListener("load", initializeSportsbook);
            }
        };

        loadScriptAndInitialize();

        // Cleanup function to stop polling
        return () => {
            if (btInstanceRef.current && btInstanceRef.current.kill) {
                btInstanceRef.current.kill(); // Ensure the correct instance is killed
                // console.log("BTRenderer instance killed");
            }
        };
    }, [brandId, jwtToken, fromRoute, betbyLaunchScreen, navigate,]);

    return (
        <>
            {/* <motion.div
                initial={{ opacity: 0, y: "100%" }}
                animate={isOpen ? "open" : "closed"}
                variants={modalVariants}
            > */}
            <div
            // style={{ height: "100dvh" }}
            >
                <div
                    // className="w-100 h-100"
                    style={{
                        position: "relative",
                    }}>


                    {/* Header */}
                    {
                        (showHeader && (betbyLaunchScreen === "mobile")) &&
                        <div
                            className="d-flex d-md-flex justify-content-between align-items-center fw-bold px-3"
                            style={{
                                backgroundColor: "var(--blackish-ash)",
                                color: "var(--white)",
                                fontSize: "1rem",
                                minHeight: "5dvh",
                            }}
                        >
                            <small className="fw-bold">{`egaming.click`}</small>

                            <div className="custom-amount-button custom-shadow">
                                <div className="d-flex align-items-center mx-2">
                                    <div className="d-flex justify-content-center align-items-center currency-icon">
                                        {requiredCurrencyIcon?.[0]?.currency_icon}
                                    </div>
                                </div>
                                <span className="ps-1 ps-md-2 mt-1">{userWallet ? (parseFloat((+userWallet?.credit_balance || 0)).toFixed(2)) : "_.__"}</span>
                                <div className="d-flex align-items-center mx-2">
                                    <TbRefresh onClick={refreshBtn} className={activeRefresh ? "rotating pointer" : "pointer"} size={"20"} color={"var(--dark)"} />
                                </div>
                                <div
                                    // onClick={() => setShowSection(!showSection)}
                                    className="d-none no-pointer amount-deposit-button">
                                    <FaPlus className="" size={"22"} color={"var(--white)"} />
                                </div>
                            </div>

                            <div className="d-flex">
                                <button
                                    style={{
                                        background: "none",
                                        border: "none",
                                        color: "var(--white)",
                                        cursor: "pointer",
                                    }}
                                    title="Full Screen"
                                    onClick={handleFullScreen}
                                >
                                    <img
                                        src={fullScreen}
                                        alt="full-screen"
                                        width={"20"}
                                        height={"20"}
                                    />
                                </button>
                                <button
                                    style={{
                                        background: "none",
                                        border: "none",
                                        color: "var(--white)",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        // setIsOpen(false);
                                        setTimeout(() => {
                                            navigate(`/${fromRoute === "exclusive" ? "" : fromRoute}`);
                                        }, 1000);
                                    }}
                                >
                                    <img
                                        src={cross}
                                        alt="cross"
                                        width={"20"}
                                        height={"20"}
                                    />
                                </button>
                            </div>
                        </div>
                    }
                    {
                        // (customLoader && !betbyRef) &&
                        <div style={{
                            position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                            zIndex: `${betbyRef ? -1 : 1}`,
                        }}>
                            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                <img src={sandTimer} alt="sand-timer" width={"50%"} />
                            </div>
                        </div>
                    }
                    {
                        <div id="betby" ref={betbyRef}
                        // style={{ width: "100%", height: "100vh" }}
                        ></div>
                    }
                </div>
            </div >
            {/* {
                (errorAlert?.visible)
                &&
                createPortal(
                    <LauncherErrorAlert close={() => closeErrorAlert(true)} item={errorAlert?.item} />,
                    Swal.getHtmlContainer() || document.body
                )
            } */}
            {/* </motion.div> */}
        </>
    );
};

export default BetByLauncher;