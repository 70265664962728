import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { userLoading } from "../../Redux/userSlice";
import { userKey } from "../../App";
import Progressbar from "../../Components/Shared/Progressbar/Progressbar";
// import Loader from "../../Components/Shared/Loader/Loader";

const PublicRoute = ({ children }) => {
    const location = useLocation();
    const loading = useSelector(userLoading);
    // const user = useSelector(userDetail);
    // console.log(user);

    if (loading === "loading") {
        return (
            <Progressbar percentage={50} />
        )
    }

    let stringValue = localStorage.getItem(userKey);
    if (stringValue) {
        return <Navigate to="/" state={{ from: location }} replace></Navigate>;
    }

    // if (user.is_login) {
    // }

    return (children);
};

export default PublicRoute;