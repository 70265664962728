import React from "react";
// import { ProgressBar } from "react-bootstrap";
import "./Progressbar.css";
// import { useThemeColor } from "../../../hooks/useThemeColor";

const loaderTop = process.env.PUBLIC_URL + "/Assets/Images/statics/loader-top1.png";
const loaderTop2 = process.env.PUBLIC_URL + "/app/logo192.png";

const Progressbar = ({ percentage }) => {
    // const { colorMode } = useThemeColor();
    return (
        <div className="start-bg">
            <div className="started-progress-bar">
                <figure className="d-flex flex-column justify-content-center align-items-center">
                    <img
                        className="shadow-image"
                        src={loaderTop2 || loaderTop}
                        alt="progressBar"
                    />
                </figure>
                {/* <ProgressBar className="" animated now={percentage} label={`${percentage}%`} /> */}
                {/* <div className={`text-center mt-2 ${colorMode === "light" ? "text-dark" : "text-white"}`}>
                    {`Loading...`}
                </div> */}
            </div>
        </div>
    );
};

export default Progressbar;