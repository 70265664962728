import { useState, useEffect } from "react";
import { rootUrl, userKey } from "../App";
import { addDataIntoCache, getCacheData } from "../Redux/cacheSlice";

export const useSliderCardMenu = () => {
    const [sliderCardMenuData, setSliderCardMenuData] = useState(null);

    useEffect(() => {
        const fetchSliderCardMenuData = async () => {
            const cacheExpirationThreshold = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
            const cacheKey = "sliderCardMenuCache";
            const cacheUrl = `${rootUrl}/game/game-menu/`;

            // Retrieve user token from local storage
            let stringValue = localStorage.getItem(userKey);
            let userToken = JSON.parse(stringValue)?.value;
            let isUserLoggedIn = !!userToken; // Check if user is logged in

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    ...(userToken && { "Authorization": `Token ${userToken}` }),
                },
            };

            // Check cache
            const cachedData = await getCacheData(cacheKey, cacheUrl);

            if (cachedData) {
                const cachedTime = new Date(cachedData.updateDate);
                const currentTime = new Date();
                const timeDifference = currentTime - cachedTime;

                const cachedUserStatus = cachedData.isUserLoggedIn; // Retrieve cached login status

                // Use cached data only if it's valid and user state matches
                if (
                    timeDifference < cacheExpirationThreshold &&
                    cachedUserStatus === isUserLoggedIn
                ) {
                    setSliderCardMenuData(cachedData.data);
                    return;
                }
            }

            // Fetch data from API if cache is invalid, expired, or user state has changed
            try {
                const response = await fetch(cacheUrl, requestOptions);
                if (!response.ok) throw response;

                const data = await response.json();
                if (data?.status === "ok") {
                    setSliderCardMenuData(data?.data);

                    // Cache the new data with updated login status
                    addDataIntoCache(cacheKey, cacheUrl, {
                        data: data?.data,
                        updateDate: new Date(),
                        isUserLoggedIn, // Store the current login status
                    });
                }
            } catch (error) {
                console.error("Error fetching slider card menu data:", error);
            }
        };

        fetchSliderCardMenuData();
    }, []);

    return {
        sliderCardMenuData,
    };
};