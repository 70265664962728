import useFetchData from "./useFetchData";

export const useCurrency = () => {
    const state = useFetchData("/configuration/currency-list/user/");
    // console.log(state?.data?.data);
    return {
        currency: state?.data?.data
    }
};

