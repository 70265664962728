import React, { useEffect, useState } from "react";
import "./DynamicGames.css";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStatics } from "../../../hooks/useStatics";
import { rootUrl } from "../../../App";
import { useSliderCardMenu } from "../../../hooks/useSliderCardMenu";
import GamesLeftSideBar from "../../../Components/Shared/LeftSideBar/GamesLeftSideBar";
import { useGameItems } from "../../../hooks/useGameItems";
import GameCard from "../../../Components/Shared/CustomCards/GameCard/GameCard";
// import Loader from "../../../Components/Shared/Loader/Loader";

import { TfiLayoutColumn2Alt } from "react-icons/tfi";
import { TfiLayoutColumn3Alt } from "react-icons/tfi";
import { FaSearch } from "react-icons/fa";
import { useThemeColor } from "../../../hooks/useThemeColor";

import Skeleton from "react-loading-skeleton";

const Footer = React.lazy(() => import("../../../Components/Shared/Footer/Footer"));

const gameDefaultProvidersData = [
    {
        "id": 0,
        "title": "All",
        "provider_name": "ALL",
        "provider_code": "ALL",
        "status": true,
        "thumbnail": null,
        "game_imgfile_name": null,
        "is_provide_gamelist": false,
        "transfer_min_limit": "N/A",
        "currency": undefined,
        "currency_rate": undefined
    },
];

const DynamicGames = ({ currentStaticGameData, installableBtnRef }) => {
    const { sliderImgList } = useStatics();
    const { sliderCardMenuData } = useSliderCardMenu();
    const { colorMode } = useThemeColor();

    const [count, setCount] = useState(3);

    // providers
    const currentGameData = sliderCardMenuData?.filter((filteredItem) => (filteredItem?.page?.name === sliderImgList?.[0]?.page_list_id?.name));
    const providers = ((currentGameData?.[0])?.game_provider) || [];
    const providerList = [...gameDefaultProvidersData, ...providers];

    // Extract the providers arrays and flatten them into a single array
    const allProviders = sliderCardMenuData?.flatMap(obj => obj?.game_provider);

    // Create a Map to store unique providers based on owner
    const uniqueProvidersMap = new Map();

    // Iterate over each providers object and add it to the Map if the provider_name is not already present
    allProviders?.forEach(provider => {
        if (!uniqueProvidersMap.has(provider?.provider_code)) {
            uniqueProvidersMap.set(provider?.provider_code, provider);
        }
    });

    // Convert the Map values back to an array
    const uniqueProvidersArray = Array.from(uniqueProvidersMap.values());

    // console.log(sliderImgList, currentGameData, providers, providerList);
    // console.log(currentGameData, providerList);

    const [selectedLeftBarData, setSelectedLeftBarData] = useState(providerList?.[0]);

    // games
    const gameType = ((currentGameData?.[0])?.game_type_code) || null;
    const { allGames } = useGameItems(gameType);
    const [showableGameList, setShowableGameList] = useState(allGames);

    const pageSize = 10;
    const [visibleImages, setVisibleImages] = useState(10); // Initial number of visible images
    const [hasMore, setHasMore] = useState(true);

    const handleLoadMore = () => {
        const newVisibleImages = visibleImages + pageSize;
        // console.log(newVisibleImages, allGames?.length, showableGameList?.length);
        if (newVisibleImages >= (allGames?.length || showableGameList?.length)) {
            setHasMore(false);
        }
        setVisibleImages(newVisibleImages);
    };

    useEffect(() => {
        // setShowableGameList(showableGameList?.slice(0, visibleImages));
        setTimeout(() => {
            handleLoadMore();
        }, 500);
    }, [visibleImages]);

    // Assuming gameCardData is the array of all images
    // const visibleImageData = gameCardData?.slice(0, visibleImages);

    // console.log(gameType);
    // console.log(allGames);

    // const selectProviderGameItems = (item) => {
    //     // console.log(item);
    // }

    const searchGames = (item) => {
        const lowerCaseItem = item?.toLowerCase();

        const filteredGames = allGames?.filter((filteredItem) => (
            (filteredItem?.title)?.toLowerCase()?.includes(lowerCaseItem)
        ) || (
                (filteredItem?.gameName?.gameName_enus)?.toLowerCase()?.includes(lowerCaseItem)
            ) || (
                (filteredItem?.Trans?.en)?.toLowerCase()?.includes(lowerCaseItem))
        );
        setShowableGameList(filteredGames);
    };

    return (
        <>
            <div className="row gx-0 py-0 d-flex justify-content-center align-items-center">
                <div className="col-12">
                    {
                        (sliderImgList?.length > 0) &&
                        <div className="position-relative">
                            <img className="w-100 dynamic-games-banner" src={rootUrl + sliderImgList[0]?.image} alt={sliderImgList[0]?.alt_text} height={""} />
                            <div className="dynamic-games-overlay"></div>
                        </div>
                    }

                </div>
            </div>

            <section className="mx-2 my-2">
                <div className="row g-0">
                    <div className="col-12">
                        <div className="game-title-area">
                            <div className="d-flex justify-content-between align-items-center game-title-bg rounded px-2 ms-1 py-1">
                                <h6 className="text-start game-title mb-0">
                                    {currentStaticGameData?.title}
                                </h6>
                                <div className="search-column d-flex justify-content-center align-items-center gap-3">
                                    <div className="d-flex justify-content-end align-items-center position-relative">
                                        {/* Icon placed with absolute positioning */}
                                        <FaSearch className="search-icon" />
                                        <input
                                            type="text"
                                            // placeholder={"Search..."}
                                            className="rounded-pill px-3"
                                            onChange={(e) => searchGames(e.target.value)}
                                        />
                                    </div>
                                    <div className="">
                                        {
                                            (count === 3) ?
                                                <TfiLayoutColumn2Alt
                                                    size={"20"}
                                                    className="pointer"
                                                    onClick={() => setCount(2)}
                                                />
                                                :
                                                <TfiLayoutColumn3Alt
                                                    size={"20"}
                                                    className="pointer"
                                                    onClick={() => setCount(3)}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mx-2 my-2">
                <div className="row g-0 gap">
                    <aside className="col-2"
                    >
                        {/* {"Side Bar"} */}
                        {
                            ((providerList)?.length > 0) &&

                            <GamesLeftSideBar
                                selectedLeftBarData={selectedLeftBarData}
                                setSelectedLeftBarData={setSelectedLeftBarData}
                                leftSideBarData={providerList}
                                allGames={allGames}
                                setShowableGameList={setShowableGameList}
                            />
                        }
                    </aside>
                    <div className="col-10"
                    >
                        <InfiniteScroll
                            dataLength={allGames?.length || showableGameList?.length || visibleImages}
                            next={handleLoadMore}
                            hasMore={hasMore}
                        >
                            {
                                (showableGameList?.length || allGames?.length) ? (
                                    <GameCard
                                        count={count}
                                        gameCardData={showableGameList?.slice(0, visibleImages) || allGames?.slice(0, visibleImages)}
                                    />
                                ) : (
                                    // Render skeleton loaders when data is not available
                                    <div className="mx-2 row g-2">
                                        {[...Array(9)].map((_, index) => (
                                            <div key={index} className="col-4">
                                                <div className="skeleton-card pb-2">
                                                    <Skeleton height={120}
                                                        baseColor={(colorMode === "dark") ? "var(--dark)" : "var(--gray)"}
                                                        highlightColor={(colorMode === "dark") ? "var(--gray)" : "var(--light-blue)"}
                                                    />
                                                    <Skeleton height={20} width="80%"
                                                        baseColor={(colorMode === "dark") ? "var(--dark)" : "var(--gray)"}
                                                        highlightColor={(colorMode === "dark") ? "var(--gray)" : "var(--light-blue)"}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </section >

            <footer>
                <Footer installableBtnRef={installableBtnRef} providersList={uniqueProvidersArray} />
            </footer>
            {/* <Loader /> */}
        </>
    );
};

export default DynamicGames;