import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
// import Spinner from "react-bootstrap/Spinner";

import "./Launcher.css";
import { rootUrl, userKey } from "../../../App";
// import MovableCross from "../../../Components/Shared/MovableCross/MovableCross";

import Swal from "sweetalert2";
import { createPortal } from "react-dom";

import LauncherErrorAlert from "../../../Components/Shared/CustomSweetAlert/LauncherErrorAlert";
import useGameErrors from "../../../hooks/useGameErrors";
// import MovableHome from "../../../Components/Shared/MovableHome/MovableHome";

import { motion } from "framer-motion";

import { useSelector } from "react-redux";
import { userDetail } from "../../../Redux/userSlice";

const fullScreen = process.env.PUBLIC_URL + "/Assets/Images/Icons/GameLaunchIcons/full-screen.svg";
const cross = process.env.PUBLIC_URL + "/Assets/Images/Icons/GameLaunchIcons/cross.svg";

const sandTimer = process.env.PUBLIC_URL + "/Assets/Images/statics/processing.gif";

const PlayTechLauncher = () => {

    const navigate = useNavigate();

    const user = useSelector(userDetail);
    const baseDomain = user?.user?.user_base_origin;

    const [launchUrl, setLaunchUrl] = useState(null);
    const [customLoader, setCustomLoader] = useState(true);

    const { p_code, p_type, g_code, operator, fromRoute, p_username, p_password, p_lang, p_client, p_mode, p_game } = useParams();

    const { findErrorByCode, closeErrorAlert, showGameErrorsSweetAlert2, errorAlert, } = useGameErrors();

    const [isOpen, setIsOpen] = useState(true);
    const [showHeader, setShowHeader] = useState(true);

    const modalVariants = {
        open: { y: 0, opacity: 1, transition: { duration: 0.8, ease: "easeInOut" } },
        closed: { y: "100%", opacity: 0, transition: { duration: 0.8, ease: "easeInOut" } },
    };

    const handleFullScreen = () => {
        setShowHeader(false);
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    };

    useEffect(() => {

        let stringValue = localStorage.getItem(userKey);
        let value = JSON.parse(stringValue);

        if (!launchUrl && value?.value) {
            // dispatch(setInnerLoaderTrue());

            if (operator === "pltech") {
                const plTechUrl = `${baseDomain}/pltechlauncher.html?p_code=${p_code}&p_type=${p_type}&g_code=${g_code || ""}&p_operator=${operator || ""}&p_username=${p_username}&p_password=${p_password}&p_lang=${p_lang}&p_client=${p_client}&p_mode=${"offline" || p_mode}&p_game=${p_game}`;
                setLaunchUrl(plTechUrl);
                return;
            }
        }
    }, [launchUrl]);

    const hideLoader = () => {
        setCustomLoader(true);
        setTimeout(async () => {
            setCustomLoader(false);
        }, 5000);
    }

    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: "100%" }}
                animate={isOpen ? "open" : "closed"}
                variants={modalVariants}
            >
                <div
                    style={{ height: "100dvh" }}
                >
                    <div
                        className="w-100 h-100"
                        style={{
                            position: "relative",
                        }}>


                        {/* Header */}
                        {
                            (showHeader) &&
                            <div
                                className="d-flex justify-content-between align-items-center fw-bold px-3"
                                style={{
                                    backgroundColor: "var(--blackish-ash)",
                                    color: "var(--white)",
                                    fontSize: "1rem",
                                    minHeight: "5dvh",
                                }}
                            >
                                <small className="fw-bold">{`egaming.click`}</small>

                                <div className="d-flex">
                                    <button
                                        style={{
                                            background: "none",
                                            border: "none",
                                            color: "var(--white)",
                                            cursor: "pointer",
                                        }}
                                        title="Full Screen"
                                        onClick={handleFullScreen}
                                    >
                                        <img
                                            src={fullScreen}
                                            alt="full-screen"
                                            width={"20"}
                                            height={"20"}
                                        />
                                    </button>
                                    <button
                                        style={{
                                            background: "none",
                                            border: "none",
                                            color: "var(--white)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setIsOpen(false);
                                            setTimeout(() => {
                                                navigate(`/${fromRoute === "exclusive" ? "" : fromRoute}`);
                                            }, 1000);
                                        }}
                                    >
                                        <img
                                            src={cross}
                                            alt="cross"
                                            width={"20"}
                                            height={"20"}
                                        />
                                    </button>
                                </div>
                            </div>
                        }

                        {
                            ((customLoader) || (launchUrl === "null")) &&
                            <div style={{
                                position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                                zIndex: 1
                            }}>
                                <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                    <img src={sandTimer} alt="sand-timer" width={"50%"} />
                                </div>
                            </div>
                        }
                        {
                            ((launchUrl !== "null") || (!customLoader)) &&
                            < iframe
                                src={launchUrl}
                                title="gameIframe"
                                name="gameIframe"
                                id="gameIframe"
                                frameBorder="0"
                                allow="autoplay"
                                allowFullScreen={true}
                                scrolling="auto"
                                width="100%"
                                height="100%"
                                marginWidth="0"
                                marginHeight="0"
                                style={{ position: "absolute", top: showHeader ? "5dvh" : 0, right: 0, bottom: 0, left: 0, minHeight: "95dvh", minWidth: "100%", height: showHeader ? "95dvh" : "100dvh", overflow: "hidden", display: "block", zIndex: 3, }}
                                onLoad={() => hideLoader}
                            ></ iframe>
                        }
                    </div>
                </div >
                {
                    (errorAlert?.visible)
                    &&
                    createPortal(
                        <LauncherErrorAlert close={() => closeErrorAlert(true)} item={errorAlert?.item} />,
                        Swal.getHtmlContainer() || document.body
                    )
                }
            </motion.div>
        </>
    );
};

export default PlayTechLauncher;