import React, { useEffect, useState } from 'react';
import "./Navigation.css";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { currentUserWallet, fetchLoginUserWallet, userDetail } from "../../../Redux/userSlice";

// import images and icons

// import { FaBell } from 'react-icons/fa';
// import { RiQrScan2Line } from "react-icons/ri";

import { useThemeColor } from "../../../hooks/useThemeColor";
import { AiFillLeftCircle } from 'react-icons/ai';
import { useCurrency } from '../../../hooks/useCurrency';
// import { useLiveNotifications } from '../../../hooks/useLiveNotifications';

import { TbRefresh } from "react-icons/tb";
import { FaPlus } from "react-icons/fa6";

const signUpIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/signup-icon.svg";
const loginIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/login-icon.svg";

const userIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-icon.svg";
const userDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-dark-icon.svg";

const depositIconWhite = process.env.PUBLIC_URL + "/Assets/Images/Icons/TransactionIcons/deposit-icon-white.svg";
const withdrawalIconWhite = process.env.PUBLIC_URL + "/Assets/Images/Icons/TransactionIcons/withdrawal-icon-white.svg";

const NavigationWithBackButtonPageName = ({ pageTitle, state = null, setState = null, unseenNotifications = null, dynamicGames = false, }) => {
    const { colorMode, } = useThemeColor();
    // const { unseenNotifications, } = useLiveNotifications();
    // console.log(unseenNotifications);
    const { currency } = useCurrency();
    // const { unseenNotifications, } = useLiveNotifications();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(userDetail);
    const userWallet = useSelector(currentUserWallet);

    const requiredCurrencyIcon = currency?.filter((filteredItem) => (filteredItem?.currency === user?.user?.currency));


    const [showSection, setShowSection] = useState(false);
    const [activeRefresh, setActiveRefresh] = useState(true);

    const refreshBtn = () => {
        setActiveRefresh(true);
    };


    useEffect(() => {
        let refreshTimer;
        if (activeRefresh) {
            // Start refreshing

            dispatch(fetchLoginUserWallet());
            refreshTimer = setTimeout(() => {
                // Stop refreshing after 1 second
                setActiveRefresh(false);
            }, 1000);
        }
        // else {
        //     // Start a timer to refresh after 30 seconds if not already refreshing
        //     refreshTimer = setTimeout(() => {
        //         setActiveRefresh(true);
        //     }, 30000);
        // }
        return () => clearInterval(refreshTimer);
    }, [activeRefresh, dispatch]);

    return (
        <div className={
            // (
            // (((pageTitle === "Deposit") || (pageTitle === "Withdrawal")) && (state === null))
            // ||
            // ((pageTitle === "Notifications") && (unseenNotifications === null))
            // ) ? "d-none"
            // : 
            "row gx-0 justify-content-between align-items-center py-1 px-2 header"
        }>
            {/* <div className="col-3 col-sm-5 d-flex justify-content-start justify-content-md-start">
                <div className="position-absolute top-0 ms-1 mt-1" style={{ zIndex: 3, }}>
                    <AiFillLeftCircle size="30" fill={(colorMode === "light") ? "var(--chocolate)" : "var(--golden)"} onClick={() => { navigate(-1) }} style={{ cursor: "pointer", }} />
                </div>
                <h1 className="fw-bold text-uppercase mb-0 py-0" style={{ color: "", textDecoration: "none", fontSize: "0.8rem" }}>
                    {pageTitle}
                </h1>
                <div>

                </div>
            </div> */}
            < div className="col-3 col-sm-5 d-flex justify-content-start justify-content-md-start align-items-center position-relative" >
                <div className="">
                    <AiFillLeftCircle size={"30"} fill={(colorMode === "light") ? "var(--chocolate)" : "var(--golden)"} onClick={() => {
                        // const state = sessionStorage.getItem("previousState");
                        if (dynamicGames) {
                            navigate("/");
                        } else {
                            if (state) {
                                setState();
                            } else {
                                navigate(-1);
                            }
                        }
                    }} style={{ cursor: "pointer", marginRight: "5px" }} />
                </div>
                <h1 className="fw-bold text-uppercase mb-0 py-0" style={{ color: (colorMode === "light") ? "var(--chocolate)" : "var(--golden)", textDecoration: "none", fontSize: "0.8rem", whiteSpace: "nowrap", }}>
                    {pageTitle}
                </h1>
            </div >
            {
                user?.is_login ?
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">

                        <div className="custom-amount-button custom-shadow me-2">
                            <div className="d-flex align-items-center mx-2">
                                <div className="d-flex justify-content-center align-items-center currency-icon">
                                    {requiredCurrencyIcon?.[0]?.currency_icon}
                                </div>
                            </div>
                            <span className="ps-1 ps-md-2 mt-1">{userWallet ? (parseFloat((+userWallet?.credit_balance || 0)).toFixed(2)) : "_.__"}</span>
                            <div className="d-flex align-items-center ms-2">
                                <TbRefresh onClick={refreshBtn} className={activeRefresh ? "rotating pointer" : "pointer"} size={"20"} color={"var(--dark)"} />
                            </div>
                            <div
                                // onClick={() => { navigate(`/deposit${user?.user?.username ? ("?" + user?.user?.username) : ""}`) }} 
                                onClick={() => setShowSection(!showSection)}
                                className="pointer amount-deposit-button">
                                <FaPlus className="" size={"22"} color={"var(--white)"} />
                            </div>
                        </div>

                        {/* < RiQrScan2Line size={25} color={(colorMode === "light") ? "" : "var(--golden)"} /> */}

                        {/* < Link className={(unseenNotifications?.length > 0) ? "mx-2 bet-notification-number blink-image" : "mx-2 bet-notification-number"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ""}`}>
                            <FaBell size={22} color="var(--golden)" />

                            <span>{(unseenNotifications?.length > 9) ? "9+" : (unseenNotifications?.length) ? unseenNotifications?.length : ""}</span>

                        </Link > */}
                        <div
                            className="nav-user-bg pointer"
                            onClick={() => navigate("/account")}
                        >
                            <img className="p-1" src={(colorMode === "light") ? userIcon : userDarkIcon} alt="user" />
                        </div>

                        {/* Animated Deposit/Withdrawal Section */}
                        <div
                            className={`animated-section ${showSection ? "visible" : ""}`}
                            style={{ top: showSection ? "38px" : "-70px" }}
                        >
                            <div className="col-12 d-flex gap-2 px-2 py-2">
                                <button
                                    onClick={() => {
                                        navigate("/withdrawal");
                                        setShowSection(false);
                                    }}
                                    className={colorMode === "light" ? "btn btn-sm dark-button w-100 py-3" : "btn btn-sm dark-button w-100 py-3"}>
                                    <img className="me-2 mb-1" src={colorMode === "light" ? withdrawalIconWhite : withdrawalIconWhite} alt="withdrawal" />
                                    {"Withdrawal"}</button>
                                <button
                                    onClick={() => {
                                        navigate("/deposit");
                                        setShowSection(false);
                                    }}
                                    className={"btn btn-sm golden-button w-100 py-3"}>
                                    <img className="me-2 mb-1" src={colorMode === "light" ? depositIconWhite : depositIconWhite} alt="deposit" />
                                    {"Deposit"}</button>
                            </div>
                        </div>
                    </div >
                    :
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">
                        <Link to={"/registration"}>
                            <button className="header-button signUp-button">
                                <img className="me-1"
                                    width={"11px"}
                                    src={signUpIcon}
                                    alt="signUp" />
                                {/* <Trans i18nKey="signup"> */}
                                SIGN UP
                                {/* </Trans> */}
                            </button>
                        </Link>

                        <Link to="/login" className="ms-2">
                            <button className="header-button login-button">
                                <img className="me-1"
                                    width={"14px"}
                                    src={loginIcon}
                                    alt="login" />
                                {/* <Trans i18nKey="login"> */}
                                LOG IN
                                {/* </Trans> */}
                            </button>
                        </Link>
                    </div>
            }
        </div >
    );
};

export default NavigationWithBackButtonPageName;